export const useGlobalStore = defineStore('global', {
  state: () => ({
    darkTheme: false,
    loadedMore: false,
    mainSeo: {},
    mainBanner: {}
  }),
  actions: {
    changeTheme() {
      this.darkTheme = !this.darkTheme
    },
    changeLoadedMore() {
      this.loadedMore = true
    },
    changeMainSeo(seoVal) {
      this.mainSeo = seoVal
    },
    changeMainBanner(bannerVal) {
      this.mainBanner = bannerVal
    }
  }
})
